const GRAPHQL_DEF_BLOCK = `
blocks {
  __typename
  ... on blocks_text_BlockType {
    text
    backLink {
      uri
    }
    logo {
      url
    }
  }
  ... on blocks_slider_BlockType {
    slide {
      __typename
      ... on slide_BlockType {
        uid
        slideTitle
        logo{
          url
        }
        button {
          target
          type
          url
          element {
            uri
            uid
          }
          text
          customText
        }
        image {
          url: url(transform: "sliderTransform")
        }
      }
    }
  }
  ... on blocks_pageBlocks_BlockType {
    pages {
      ... on pages_page_Entry {
        title
        uid
        uri
        url
        text
        images {
          url: url(transform: "headerTransform")
          url_small: url(transform: "pageTransform")
        }
      }
      ... on participations_participation_Entry {
        title
        uid
        uri
        url
        text
        images {
          url: url(transform: "pageTransform")
        }
      }
    }
  }
  ... on blocks_participations_BlockType {
    participationCategories {
      id
      title
    }
  }
}
`;

const GRAPHQL_DEF_HOMEPAGE = `
... on home_home_Entry {
  title
  uri
  url
  images {
    ... on AssetInterface {
      id
      title
      url: url(transform: "headerTransform")
      url_small: url(transform: "pageTransform")
    }
  }
  seo {
    title
    description
  }
  ${GRAPHQL_DEF_BLOCK}
}
`;

const GRAPHQL_DEF_PAGE = `
... on pages_page_Entry {
  title
  uri
  url
  text
  images {
    ... on AssetInterface {
      id
      title
      url: url(transform: "headerTransform")
      url_small: url(transform: "pageTransform")
    }
  }
  seo {
    title
    description
  }
  ${GRAPHQL_DEF_BLOCK}
}
`;

const GRAPHQL_DEF_PARTICIPATION = `
... on participations_participation_Entry {
  title
  uri
  url
  text
  participationCategories {
    id
    title
  }
  images {
    ... on AssetInterface {
      id
      title
      url: url(transform: "headerTransform")
      url_small: url(transform: "pageTransform")
    }
  }
  seo {
    title
    description
  }
  ${GRAPHQL_DEF_BLOCK}
}
`;

const GRAPHQL_DEF_NODE = `
navHandle
title
uri
url
type
element {
  id
  uid
  uri
  __typename
}
`;

const GRAPHQL_QUERY_ENTRY = `
query EntryQuery ($uri: [String]){
  entry(uri: $uri) {
    ${GRAPHQL_DEF_HOMEPAGE}
    ${GRAPHQL_DEF_PAGE}
    ${GRAPHQL_DEF_PARTICIPATION}
  }
}
`;

const GRAPHQL_QUERY_PARTICIPATION = `
query ParticipationQuery ($categoryId:QueryArgument){
  entries(section: "participations", participationCategories: [$categoryId]) {
    ${GRAPHQL_DEF_PARTICIPATION}
  }
}
`;

const GRAPHQL_QUERY_MENU = `
query MenuQuery {
  nodes (level: 1) {
    ${GRAPHQL_DEF_NODE}
    children {
      ${GRAPHQL_DEF_NODE}
    }
  }
}
`;

export {
  GRAPHQL_QUERY_ENTRY,
  GRAPHQL_QUERY_PARTICIPATION,
  GRAPHQL_QUERY_MENU,
};
