import _ from 'lodash';
import NAVIGATION_FETCHED from './types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATION_FETCHED: {
      const navigationNodes = _.get(action, 'payload.data.data.nodes', []);

      const fixedNodes = _.map(navigationNodes, (node) => {
        if (_.get(node, 'element.uri', false) === '__home__') {
          const temp = {
            element: {
              uri: '',
            },
          };
          return { ...node, ...temp };
        }
        return node;
      });

      const newNavigation = _.groupBy(fixedNodes, (node) => node.navHandle);

      return (state === null) ? newNavigation : { ...state, ...newNavigation };
    }
    default: {
      return state;
    }
  }
};
