import React from 'react';
import PropTypes from 'prop-types';

const Text = ({ body, link, logo }) => {
  const backButton = link ? (<a href={link} className="terug">&lt; Terug naar deelnemingen</a>) : false;
  const logoImg = logo ? (<img src={logo} className="logo" alt="logo" />) : false;
  const blockClass = logo ? 'mt-5 pt-5' : 'mt-5';

  return (
    <div className="container">
      <div className="row">
        <div className="offset-sm-1 col-md-10">
          <div className="introTekst text-sm-center shadow-sm">
            <img src="/themes/thema-scholtgroup/assets/images/xbeeldmerk.png.pagespeed.ic.ZdYQByphLO.png" className="beeldmerk" alt="" />
            {backButton}
            {logoImg}
            <div
              className={blockClass}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Text;

Text.defaultProps = {
  body: '',
};

Text.propTypes = {
  body: PropTypes.string,
};
