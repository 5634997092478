import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContent from '../../containers/PageContent/PageContent';

const RegularPage = ({ location: { pathname } }) => (
  <div className="regular-page">
    <Header />
    <PageContent currentUrl={pathname.substring(1) !== '' ? pathname.substring(1) : '__home__'} />
    <Footer />
  </div>
);

export default RegularPage;
