import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchNavigation from '../../redux-modules/navigation/actions';
import FooterMenuList from '../../components/FooterMenuList';

class FooterMenu extends Component {
  componentDidMount() {
    const { fetchNavigation: fetchNavigationAction } = this.props;

    fetchNavigationAction();
  }

  render = () => {
    const { navigations } = this.props;

    return <FooterMenuList nodes={_.get(navigations, 'footerNavigation', [])} />;
  }
}

const mapStateToProps = (state) => ({
  navigations: state.navigation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { fetchNavigation },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(FooterMenu);

FooterMenu.propTypes = {
  fetchNavigation: PropTypes.func.isRequired,
  navigations: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
};
